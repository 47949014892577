@import '@speed/common/src/components/core.scss';
@import '@speed/common/src/assets/styles/common.scss';

body {
    margin: 0;
}

.checkout-page-box {
    @include commonCheckoutPageBoxCss();
}

@media (min-width:320px) and (max-width:600px) {
    .checkout-page-box {
        min-height: 100vh;

        .mobile-view-branding {
            @include commonMobileViewBranding()
        }
    }
}